<template>
  <v-form v-model="formIsValid" ref="form" @submit.prevent="submitForm">
    <v-row class="flex-column">
      <v-col>
        <v-select
          v-model="criteria.operator"
          :items="availableOperators"
          label="Condición"
          :rules="[$data.$globalRules.required]"
          @change="resetValues"
        />
        <div class="mb-2" v-if="criteria.operator === 'btw'">
          <div class="mb-1 text-caption text-center">Selección rápida</div>
          <v-row dense>
            <v-col cols="4">
              <v-btn
                :color="lastMonthSelected ? 'primary' : 'grey'"
                :outlined="!!lastMonthSelected"
                text
                x-small
                class="mr-2"
                @click="selectLastMonth"
              >
                mes pasado
              </v-btn>
            </v-col>
            <v-col cols="4" class="text-center">
              <v-btn
                :color="thisMonthSelected ? 'primary' : 'grey'"
                :outlined="!!thisMonthSelected"
                text
                x-small
                class="mr-2"
                @click="selectThisMonth"
              >
                este mes
              </v-btn>
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                :color="nextMonthSelected ? 'primary' : 'grey'"
                :outlined="!!nextMonthSelected"
                text
                x-small
                class="mr-2"
                @click="selectNextMonth"
              >
                siguiente mes
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
        <v-date-picker
          v-model="criteria.value"
          show-current
          :range="criteria.operator === 'btw'"
          full-width
          locale="ES"
          no-title
          first-day-of-week="1"
          show-adjacent-months
          class="pb-1"
        />
        <v-text-field
          :value="dateRangeText"
          :rules="[$data.$globalRules.required]"
          placeholder="Fecha"
          class="mt-0 pt-0"
          readonly
          prepend-icon="mdi-calendar"
        />
      </v-col>
      <v-col class="text-right">
        <slot name="actions"></slot>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      criteria: {
        operator: "e",
        value: null,
      },
      formIsValid: null,
      availableOperators: [
        { text: "Fecha concreta", value: "e" },
        { text: "Rango de fechas", value: "btw" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      if (!this.criteria.value) {
        return null;
      } else if (Array.isArray(this.criteria.value)) {
        let range = [];
        for (const date of this.criteria.value) {
          range.push(this.$moment(date).format("DD/MM/YY"));
        }
        return range.join(" a ");
      } else {
        return this.$moment(this.criteria.value).format("DD/MM/YY");
      }
    },
    lastMonthSelected() {
      if (!Array.isArray(this.criteria.value)) return null;

      const fromDate = this.$moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
      const toDate = this.$moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

      return this.criteria.value[0] === fromDate && this.criteria.value[1] === toDate;
    },
    thisMonthSelected() {
      if (!Array.isArray(this.criteria.value)) return null;

      const fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
      const toDate = this.$moment().endOf("month").format("YYYY-MM-DD");

      return this.criteria.value[0] === fromDate && this.criteria.value[1] === toDate;
    },
    nextMonthSelected() {
      if (!Array.isArray(this.criteria.value)) return null;

      const fromDate = this.$moment().add(1, "months").startOf("month").format("YYYY-MM-DD");
      const toDate = this.$moment().add(1, "months").endOf("month").format("YYYY-MM-DD");

      return this.criteria.value[0] === fromDate && this.criteria.value[1] === toDate;
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        this.$emit("submitForm", this.criteria);
      }
    },
    selectLastMonth() {
      const startOfMonth = this.$moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = this.$moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
      this.criteria.value = [];
      this.criteria.value = [startOfMonth, endOfMonth];
    },
    selectThisMonth() {
      const startOfMonth = this.$moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = this.$moment().endOf("month").format("YYYY-MM-DD");
      this.criteria.value = [];
      this.criteria.value = [startOfMonth, endOfMonth];
    },
    selectNextMonth() {
      const startOfMonth = this.$moment().add(1, "months").startOf("month").format("YYYY-MM-DD");
      const endOfMonth = this.$moment().add(1, "months").endOf("month").format("YYYY-MM-DD");
      this.criteria.value = [];
      this.criteria.value = [startOfMonth, endOfMonth];
    },
    resetValues() {
      this.criteria.value = null;
    },
  },
};
</script>

<style scoped></style>
